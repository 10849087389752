import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { Box, Heading } from "@chakra-ui/react";

import Layout from "../components/Layout";
import Hero from "../components/Hero";
import Container from "../components/Container";
import Content, { HTMLContent } from "../components/Content";

export const NaprapatPageTemplate = ({ title, heroImage, heroVisible, content, contentComponent }) => {
  const PageContent = contentComponent || Content;

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {heroVisible && (
        <Hero img={!!heroImage.childImageSharp ? heroImage.childImageSharp.fluid.src : heroImage}>
          <Heading as="h1" size="2xl">
            {title}
          </Heading>
        </Hero>
      )}
      <Container>
        <Box py="3rem">
          {!heroVisible && (
            <Heading as="h1" size="xl" pb={6}>
              {title}
            </Heading>
          )}
          <PageContent className="content" content={content} />
        </Box>
      </Container>
    </>
  );
};

NaprapatPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  heroImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  heroVisible: PropTypes.bool,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const NaprapatPage = ({ data, ...props }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout {...props}>
      <NaprapatPageTemplate
        title={post.frontmatter.title}
        heroImage={post.frontmatter.heroImage}
        heroVisible={post.frontmatter.heroVisible}
        content={post.html}
        contentComponent={HTMLContent}
      />
    </Layout>
  );
};

NaprapatPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default NaprapatPage;

export const NaprapatPageQuery = graphql`
  query NaprapatPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        heroVisible
        heroImage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
